<template>
  <div
    id="admissions_form"
    class="auth-wrapper auth-v2"
  >
    <b-row class="auth-inner">
      <b-col
        lg="8"
        class="w-full"
      >
        <b-row class="h-50">
          <b-col
            md="6"
            class="col-centent col-content-1-1"
          >
            <div class="text-center">
              <span class="text-content text-content-1">Mầm non song ngữ</span>
              <div
                class="img-details"
                onclick="window.open('https://misvn.edu.vn/tuyen-sinh-mam-non-tien-tieu-hoc-nam-hoc-2023-2024/')"
              >
                <b-img src="@/assets/images/pages/mamnon.png" />
              </div>
            </div>
          </b-col>
          <b-col
            md="6"
            class="col-centent col-content-1-2"
          >
            <div class="text-center">
              <span class="text-content text-content-2">Tiểu học</span>
              <div
                class="img-details"
                onclick="window.open('https://misvn.edu.vn/thong-tin-tuyen-sinh-tieu-hoc-nam-hoc-2024-2025/')"
              >
                <b-img src="@/assets/images/pages/tieuhoc.png" />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="h-50">
          <b-col
            md="6"
            class="p-0"
            style="background-color: #CE181B"
          >
            <div class="col-centent col-content-2-1">
              <div class="text-center">
                <span class="text-content text-content-3">Trung học cơ sở</span>
                <div
                  class="img-details"
                  onclick="window.open('https://misvn.edu.vn/thong-tin-tuyen-sinh-thcs-nam-hoc-2024-2025/')"
                >
                  <b-img src="@/assets/images/pages/thcs.png" />
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            md="6"
            class="p-0"
            style="background-color: #E3E3E3"
          >
            <div class="col-centent col-content-2-2">
              <div class="text-center">
                <span class="text-content text-content-4">Trung học phổ thông</span>
                <div
                  class="img-details"
                  onclick="window.open('https://misvn.edu.vn/thong-tin-tuyen-sinh-thpt-nam-hoc-2024-2025/')"
                >
                  <b-img src="@/assets/images/pages/thpt.png" />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        lg="4"
        class="col-content-3 px-4 pt-4"
      >
        <div class="logo-mis">
          <b-img
            fluid
            src="@/assets/images/logo/logo-mis.png"
          />
        </div>
        <b-row class="mt-3">
          <b-col
            cols="12"
            class="text-center"
          >
            <span class="text-content text-content-big">TUYỂN SINH</span>
          </b-col>
          <b-col
            cols="12"
            class="text-center"
          >
            <span class="text-content text-content-big">NĂM HỌC 2024-2025</span>
          </b-col>
        </b-row>
        <b-row class="mt-2 d-flex justify-content-center">
          <b-col
            cols="10"
            class="rectangle-sale"
          >
            <b-row>
              <b-col
                cols="12"
                class="sale-text"
              >
                <span
                  class="sale"
                >ƯU ĐÃI</span>
                <span
                  class="upto"
                >lên tới</span>
              </b-col>
              <b-col
                cols="12"
                class="mt-xl-n4 px-lg-4 mb-n2 mt-lg-n5 percent-text"
              >
                <span
                  class="float-right percent"
                >50%</span>
              </b-col>
              <b-col
                cols="12"
                class="tuition mt-n2 mb-n1"
              >
                <span
                  class="float-right content-text"
                >học phí năm học đầu tiên<br>& nhiều quà tặng giá trị</span>
              </b-col>
              <b-col
                cols="12"
              >
                <div class="divider-strong" />
              </b-col>
              <b-col
                clos="12"
                class="text-center mt-1"
              >
                <span
                  class="register-text"
                >Đăng Ký Tư Vấn Chương Trình Học</span>
              </b-col>
              <b-col cols="12">
                <div class="divider-slim" />
              </b-col>
              <b-col
                cols="12"
                class="text-center my-2"
              >
                <div class="d-flex justify-content-center">
                  <div
                    class="btn-admission"
                    @click="$router.push('/dang-ky')"
                  >
                    <span class="text-btn">ĐĂNG KÝ NGAY</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="infomation-text mt-2"
          >
            <div class="infomation">
              <b-row>
                <b-col cols="12">
                  <span
                    class="text-details text-details-big"
                  >Trường Tiểu học, THCS&THPT Đa Trí Tuệ - MIS</span>
                </b-col>
                <b-col
                  cols="12"
                  style="margin-top: 0.4rem"
                >
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3_648)">
                      <path
                        d="M16.625 7.91666C16.625 13.4583 9.5 18.2083 9.5 18.2083C9.5 18.2083 2.375 13.4583 2.375 7.91666C2.375 6.02699 3.12567 4.21472 4.46186 2.87852C5.79806 1.54232 7.61033 0.791656 9.5 0.791656C11.3897 0.791656 13.2019 1.54232 14.5381 2.87852C15.8743 4.21472 16.625 6.02699 16.625 7.91666Z"
                        stroke="#8F8894"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 10.2917C10.8117 10.2917 11.875 9.22833 11.875 7.91666C11.875 6.60498 10.8117 5.54166 9.5 5.54166C8.18832 5.54166 7.125 6.60498 7.125 7.91666C7.125 9.22833 8.18832 10.2917 9.5 10.2917Z"
                        stroke="#8F8894"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_648">
                        <rect
                          width="19"
                          height="19"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="text-details-small">Số 37 Hoàng Quán Chi, Dịch Vọng, Cầu Giấy</span>
                </b-col>
                <b-col
                  cols="12"
                  style="margin-top: 0.4rem"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      opacity="0.5"
                      clip-path="url(#clip0_4_216)"
                    >
                      <path
                        d="M18.3333 14.1V16.6C18.3343 16.8321 18.2867 17.0618 18.1937 17.2745C18.1008 17.4871 17.9644 17.678 17.7934 17.8349C17.6224 17.9918 17.4205 18.1112 17.2006 18.1856C16.9808 18.26 16.7478 18.2876 16.5167 18.2667C13.9523 17.988 11.4892 17.1118 9.32498 15.7083C7.31151 14.4289 5.60443 12.7218 4.32499 10.7083C2.91663 8.53435 2.04019 6.05917 1.76665 3.48334C1.74583 3.2529 1.77321 3.02064 1.84707 2.80136C1.92092 2.58208 2.03963 2.38058 2.19562 2.20969C2.35162 2.0388 2.54149 1.90227 2.75314 1.80878C2.9648 1.71529 3.1936 1.66689 3.42499 1.66668H5.92499C6.32941 1.6627 6.72148 1.80591 7.02812 2.06962C7.33476 2.33333 7.53505 2.69955 7.59165 3.10001C7.69717 3.90006 7.89286 4.68562 8.17499 5.44168C8.2871 5.73995 8.31137 6.0641 8.24491 6.37574C8.17844 6.68738 8.02404 6.97344 7.79998 7.20001L6.74165 8.25834C7.92795 10.3446 9.65536 12.072 11.7417 13.2583L12.8 12.2C13.0266 11.976 13.3126 11.8216 13.6243 11.7551C13.9359 11.6886 14.26 11.7129 14.5583 11.825C15.3144 12.1071 16.0999 12.3028 16.9 12.4083C17.3048 12.4655 17.6745 12.6693 17.9388 12.9813C18.203 13.2932 18.3435 13.6913 18.3333 14.1Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4_216">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="text-details-small">024 60 278 666</span>
                </b-col>
                <b-col
                  cols="12"
                  style="margin-top: 0.4rem"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M3.33335 3.33334H16.6667C17.5834 3.33334 18.3334 4.08334 18.3334 5.00001V15C18.3334 15.9167 17.5834 16.6667 16.6667 16.6667H3.33335C2.41669 16.6667 1.66669 15.9167 1.66669 15V5.00001C1.66669 4.08334 2.41669 3.33334 3.33335 3.33334Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.3334 5L10 10.8333L1.66669 5"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                  <span class="text-details-small">c123mis@hanoiedu.vn</span>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BCard,
  BImg,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BButton,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';

#admissions_form {
  background-color: #ffffff;
  height: 100%;

  .col-centent {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-content {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .infomation {
    position: relative;
    display: flex;
    align-items: end;
  }

  @media (min-width: 1600px) {
    .col-content-1-1 {
      background-color: #CE181B;
      border-top-right-radius: 50px;
    }

    .col-content-1-2 {
      background-color: #E3E3E3;
      border-top-left-radius: 50px;
    }

    .col-content-2-1 {
      background-color: #E3E3E3;
      border-top-right-radius: 50px;
      height: 100%;
    }

    .col-content-2-2 {
      background-color: #CE181B;
      border-top-left-radius: 50px;
      height: 100%;
    }

    .col-content-3 {
      img {
        padding-inline: 1rem;
      }

      .logo-mis {
        padding-inline: 3rem;
      }

      .text-content-big {
        color: #CE181B;
        font-size: 40px;
      }

      .text-details {
        color: rgba(15, 0, 26, .5);
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word
      }

      .text-details-big {
        font-size: 18px;
      }

      .text-details-small {
        font-size: 16px;
        margin-left: 10px;
      }

      .divider-strong {
        height: 8px;
        background-color: #ffffff;
        margin-top: 2rem;
        margin-inline: 4rem;
      }

      .divider-slim {
        height: 2px;
        background-color: #ffffff;
        margin-top: 1rem;
        margin-inline: 10rem;
      }

      .sale {
        color: white;
        font-size: 40px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
      }

      .upto {
        color: white;
        font-size: 25px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        margin-left: 1rem
      }

      .percent {
        color: #FFCB47;
        font-size: 130px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word
      }

      .content-text {
        color: white;
        font-size: 25px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        text-align: right
      }

      .register-text {
        color: white;
        font-family: Roboto, sans-serif;
        font-size: 23.5px;
        font-weight: 700;
        line-height: normal;
      }

      .tuition {
        padding-inline: 3rem;
      }

      .sale-text {
        padding-inline: 5rem;
      }
    }

    .text-content-1 {
      text-align: center;
      color: white;
      font-size: 30px;
    }

    .text-content-2 {
      text-align: center;
      color: #CE181B;
      font-size: 30px;
    }

    .text-content-3 {
      text-align: center;
      color: #CE181B;
      font-size: 30px;
    }

    .text-content-4 {
      text-align: center;
      color: white;
      font-size: 30px;
    }

    .rectangle-sale {
      width: 100%;
      height: auto;
      background: #CE181B;
      border-radius: 25px;
      padding-top: 1rem;

      /* CSS */
      .btn-admission {
        backface-visibility: hidden;
        background: #FFCB47;
        border: 0;
        border-radius: 20px;
        width: 250px;
        height: 50px;
        //box-sizing: border-box;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
      }

      .text-btn {
        color: #E22020;
        font-family: Roboto, sans-serif;
        font-size: 20px;
        font-weight: 700;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        letter-spacing: -.01em;
        line-height: 1.3;
        text-decoration: none;
      }

      .btn-admission:not(:disabled):hover {
        transform: scale(1.3);

        .text-btn {
          transform: scale(1.4);
        }
      }
    }

    .img-details {
      width: 433px;
      height: 283px;
      border-radius: 23px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 433px;
        height: 283px;
        border-radius: 23px;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: 0.5s all ease-in-out;
        transition: 0.5s all ease-in-out;
      }
    }

    .img-details:hover img {
      width: 433px;
      height: 283px;
      border-radius: 23px;
      transform: scale(1.35);
    }
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    .col-content-1-1 {
      background-color: #CE181B;
      border-top-right-radius: 50px;
    }

    .col-content-1-2 {
      background-color: #E3E3E3;
      border-top-left-radius: 50px;
    }

    .col-content-2-1 {
      background-color: #E3E3E3;
      border-top-right-radius: 50px;
      height: 100%;
    }

    .col-content-2-2 {
      background-color: #CE181B;
      border-top-left-radius: 50px;
      height: 100%;
    }

    .col-content-3 {

      .text-content-big {
        color: #CE181B;
        font-size: 30px;
      }

      .text-details {
        color: rgba(15, 0, 26, .5);
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word
      }

      .text-details-big {
        font-size: 14px;
      }

      .text-details-small {
        font-size: 12px;
        margin-left: 10px;
      }

      .divider-strong {
        height: 6px;
        background-color: #ffffff;
        margin-top: 2rem;
        margin-inline: 4rem;
      }

      .divider-slim {
        height: 2px;
        background-color: #ffffff;
        margin-top: 1rem;
        margin-inline: 8rem;
      }

      .sale {
        color: white;
        font-size: 30px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
      }

      .upto {
        color: white;
        font-size: 20px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        margin-left: 1rem
      }

      .percent {
        color: #FFCB47;
        font-size: 110px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word
      }

      .content-text {
        color: white;
        font-size: 18px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        text-align: right
      }

      .register-text {
        color: white;
        font-family: Roboto, sans-serif;
        font-size: 17px;
        font-weight: 700;
        line-height: normal;
      }

      .logo-mis {
        padding-inline: 2rem;
      }

      .tuition {
        padding-inline: 2rem;
      }

      .sale-text {
        padding-inline: 3.5rem;
        margin-bottom: 1rem;
      }
    }

    .text-content-1 {
      text-align: center;
      color: white;
      font-size: 20px;
    }

    .text-content-2 {
      text-align: center;
      color: #CE181B;
      font-size: 20px;
    }

    .text-content-3 {
      text-align: center;
      color: #CE181B;
      font-size: 20px;
    }

    .text-content-4 {
      text-align: center;
      color: white;
      font-size: 20px;
    }

    .rectangle-sale {
      width: 100%;
      height: auto;
      background: #CE181B;
      border-radius: 25px;
      padding-top: 0.75rem;

      /* CSS */
      .btn-admission {
        backface-visibility: hidden;
        background: #FFCB47;
        border: 0;
        border-radius: 20px;
        width: 200px;
        height: 50px;
        //box-sizing: border-box;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
      }

      .text-btn {
        color: #E22020;
        font-family: Roboto, sans-serif;
        font-size: 20px;
        font-weight: 700;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        letter-spacing: -.01em;
        line-height: 1.3;
        text-decoration: none;
      }

      .btn-admission:not(:disabled):hover {
        transform: scale(1.2);

        .text-btn {
          transform: scale(1.3);
        }
      }
    }

    .img-details {
      width: 370px;
      height: 220px;
      border-radius: 23px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 370px;
        height: 220px;
        border-radius: 23px;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: 0.5s all ease-in-out;
        transition: 0.5s all ease-in-out;
      }
    }

    .img-details:hover img {
      width: 370px;
      height: 220px;
      border-radius: 23px;
      transform: scale(1.35);
    }

    infomation-text {
      padding-inline: 1.5rem;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .col-content-1-1 {
      background-color: #CE181B;
      border-top-right-radius: 40px;
    }

    .col-content-1-2 {
      background-color: #E3E3E3;
      border-top-left-radius: 40px;
    }

    .col-content-2-1 {
      background-color: #E3E3E3;
      border-top-right-radius: 40px;
      height: 100%;
    }

    .col-content-2-2 {
      background-color: #CE181B;
      border-top-left-radius: 40px;
      height: 100%;
    }

    .col-content-3 {
      img {
        padding-inline: 1rem;
      }

      .text-content-big {
        color: #CE181B;
        font-size: 25px;
      }

      .text-details {
        color: rgba(15, 0, 26, .5);
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        line-height: 12px;
        word-wrap: break-word
      }

      .text-details-big {
        font-size: 10px;
      }

      .text-details-small {
        font-size: 8px;
        margin-left: 5px;
      }

      .divider-strong {
        height: 5px;
        background-color: #ffffff;
        margin-top: 2rem;
        margin-inline: 2rem;
      }

      .divider-slim {
        height: 2px;
        background-color: #ffffff;
        margin-top: 1rem;
        margin-inline: 4rem;
      }

      .sale {
        color: white;
        font-size: 20px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
      }

      .upto {
        color: white;
        font-size: 12px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        margin-left: 1rem
      }

      .percent {
        color: #FFCB47;
        font-size: 70px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
        margin-top: 2.5rem;
      }

      .content-text {
        color: white;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        text-align: right;
        margin-top: 1.5rem
      }

      .register-text {
        color: white;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
      }
    }

    .text-content-1 {
      text-align: center;
      color: white;
      font-size: 20px;
    }

    .text-content-2 {
      text-align: center;
      color: #CE181B;
      font-size: 20px;
    }

    .text-content-3 {
      text-align: center;
      color: #CE181B;
      font-size: 20px;
    }

    .text-content-4 {
      text-align: center;
      color: white;
      font-size: 20px;
    }

    .rectangle-sale {
      width: 100%;
      height: auto;
      background: #CE181B;
      border-radius: 25px;
      padding-top: 1rem;

      /* CSS */
      .btn-admission {
        backface-visibility: hidden;
        background: #FFCB47;
        border: 0;
        border-radius: 20px;
        width: 150px;
        height: 50px;
        //box-sizing: border-box;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
      }

      .text-btn {
        color: #E22020;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 700;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        letter-spacing: -.01em;
        line-height: 1.3;
        text-decoration: none;
      }

      .btn-admission:not(:disabled):hover {
        transform: scale(1.3);

        .text-btn {
          transform: scale(1.4);
        }
      }
    }

    .img-details {
      width: 280px;
      height: 150px;
      border-radius: 18px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 280px;
        height: 150px;
        border-radius: 18px;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: 0.5s all ease-in-out;
        transition: 0.5s all ease-in-out;
      }
    }

    .img-details:hover img {
      width: 280px;
      height: 150px;
      border-radius: 18px;
      transform: scale(1.35);
    }

    infomation-text {
      padding-inline: 1.5rem;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .col-content-1-1 {
      background-color: #CE181B;
      border-top-right-radius: 30px;
      height: 250px;
    }

    .col-content-1-2 {
      background-color: #E3E3E3;
      border-top-left-radius: 30px;
      height: 250px;
    }

    .col-content-2-1 {
      background-color: #E3E3E3;
      border-top-right-radius: 30px;
      height: 250px;
    }

    .col-content-2-2 {
      background-color: #CE181B;
      border-top-left-radius: 30px;
      height: 250px;
    }

    .col-content-3 {
      .logo-mis {
        display: flex;
        justify-content: center;
      }

      img {
        padding-inline: 1rem;
      }

      .text-content-big {
        color: #CE181B;
        font-size: 40px;
      }

      .text-details {
        color: rgba(15, 0, 26, .5);
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        line-height: 15px;
        word-wrap: break-word
      }

      .text-details-big {
        font-size: 20px;
      }

      .text-details-small {
        font-size: 14px;
        margin-left: 5px;
      }

      .divider-strong {
        height: 8px;
        background-color: #ffffff;
        margin-top: 2rem;
        margin-inline: 4rem;
      }

      .divider-slim {
        height: 2px;
        background-color: #ffffff;
        margin-top: 1rem;
        margin-inline: 10rem;
      }

      .sale-text {
        padding-inline: 10rem;
      }

      .sale {
        color: white;
        font-size: 50px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
      }

      .upto {
        color: white;
        font-size: 30px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        margin-left: 1rem
      }

      .percent-text {
        padding-inline: 10rem;
      }

      .percent {
        color: #FFCB47;
        font-size: 150px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
        margin-top: -2rem !important;
      }

      .tuition {
        padding-inline: 10rem;
      }

      .content-text {
        color: white;
        font-size: 25px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        text-align: right
      }

      .register-text {
        color: white;
        font-family: Roboto, sans-serif;
        font-size: 25px;
        font-weight: 700;
        line-height: normal;
      }
    }

    .text-content-1 {
      text-align: center;
      color: white;
      font-size: 20px;
    }

    .text-content-2 {
      text-align: center;
      color: #CE181B;
      font-size: 20px;
    }

    .text-content-3 {
      text-align: center;
      color: #CE181B;
      font-size: 20px;
    }

    .text-content-4 {
      text-align: center;
      color: white;
      font-size: 20px;
    }

    .rectangle-sale {
      width: 100%;
      height: auto;
      background: #CE181B;
      border-radius: 15px;
      padding-top: 1rem;

      /* CSS */
      .btn-admission {
        backface-visibility: hidden;
        background: #FFCB47;
        border: 0;
        border-radius: 15px;
        width: 300px;
        height: 50px;
        //box-sizing: border-box;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
      }

      .text-btn {
        color: #E22020;
        font-family: Roboto, sans-serif;
        font-size: 25px;
        font-weight: 700;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        letter-spacing: -.01em;
        line-height: 1.3;
        text-decoration: none;
      }

      .btn-admission:not(:disabled):hover {
        transform: scale(1.3);

        .text-btn {
          transform: scale(1.4);
        }
      }
    }

    .img-details {
      width: 300px;
      height: 180px;
      border-radius: 18px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 300px;
        height: 180px;
        border-radius: 18px;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: 0.5s all ease-in-out;
        transition: 0.5s all ease-in-out;
      }
    }

    .img-details:hover img {
      width: 300px;
      height: 180px;
      border-radius: 18px;
      transform: scale(1.35);
    }
  }

  @media (max-width: 767px) {
    .col-content-1-1 {
      background-color: #CE181B;
      height: 250px;
    }

    .col-content-1-2 {
      background-color: #E3E3E3;
      height: 250px;
    }

    .col-content-2-1 {
      background-color: #CE181B;
      height: 250px;
    }

    .col-content-2-2 {
      background-color: #E3E3E3;
      height: 250px;
    }

    .col-content-3 {
      .logo-mis {
        display: flex;
        justify-content: center;
      }

      img {
        padding-inline: 2rem;
      }

      .text-content-big {
        color: #CE181B;
        font-size: 30px;
      }

      .text-details {
        color: rgba(15, 0, 26, .5);
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        line-height: 15px;
        word-wrap: break-word;
      }

      .text-details-big {
        font-size: 16px;
      }

      .text-details-small {
        font-size: 12px;
        margin-left: 5px;
      }

      .divider-strong {
        height: 5px;
        background-color: #ffffff;
        margin-top: 2rem;
        margin-inline: 3rem;
      }

      .divider-slim {
        height: 2px;
        background-color: #ffffff;
        margin-top: 1rem;
        margin-inline: 6rem;
      }

      .sale-text {
        padding-inline: 3.5rem;
      }

      .sale {
        color: white;
        font-size: 40px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
      }

      .upto {
        color: white;
        font-size: 25px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        margin-left: 1rem
      }

      .percent-text {
        padding-inline: 2rem;
      }

      .percent {
        color: #FFCB47;
        font-size: 120px;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        word-wrap: break-word;
        margin-top: -2rem !important;
      }

      .tuition {
        padding-inline: 2rem;
      }

      .content-text {
        color: white;
        font-size: 20px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        word-wrap: break-word;
        text-align: right
      }

      .register-text {
        color: white;
        font-family: Roboto, sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
      }
    }

    .text-content-1 {
      text-align: center;
      color: white;
      font-size: 20px;
    }

    .text-content-2 {
      text-align: center;
      color: #CE181B;
      font-size: 20px;
    }

    .text-content-3 {
      text-align: center;
      color: white;
      font-size: 20px;
    }

    .text-content-4 {
      text-align: center;
      color: #CE181B;
      font-size: 20px;
    }

    .rectangle-sale {
      width: 100%;
      height: auto;
      background: #CE181B;
      border-radius: 15px;
      padding-top: 1rem;

      /* CSS */
      .btn-admission {
        backface-visibility: hidden;
        background: #FFCB47;
        border: 0;
        border-radius: 15px;
        width: 300px;
        height: 50px;
        //box-sizing: border-box;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
      }

      .text-btn {
        color: #E22020;
        font-family: Roboto, sans-serif;
        font-size: 25px;
        font-weight: 700;
        transform: translateZ(0) scale(1);
        transition: transform .5s;
        letter-spacing: -.01em;
        line-height: 1.3;
        text-decoration: none;
      }

      .btn-admission:not(:disabled):hover {
        transform: scale(1.3);

        .text-btn {
          transform: scale(1.4);
        }
      }
    }

    .img-details {
      width: 300px;
      height: 180px;
      border-radius: 18px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 300px;
        height: 180px;
        border-radius: 18px;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: 0.5s all ease-in-out;
        transition: 0.5s all ease-in-out;
      }
    }

    .img-details:hover img {
      width: 300px;
      height: 180px;
      border-radius: 18px;
      transform: scale(1.35);
    }
  }
}

</style>
